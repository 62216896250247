import React, { useEffect, useState, useContext } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";
import { useIndexQuery,useIndexWeightingQuery,useIndexEventsQuery,useIndexEventDeleteMutation, useIndexEventRunMutation, useIndexEventCompareMutation } from '../../../store/indexOneApi';

import Grid from '@mui/material/Grid2'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton'
import Tooltip from '@mui/material/Tooltip'

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem'
import LoadingButton from '@mui/lab/LoadingButton'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import StageChip from '../../elements/StageChip'
import LoadingScreen from '../../elements/LoadingScreen'

import { NotificationContext } from '../../elements/NotificationContext';

import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

//table
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

//other icons
import DeleteIcon from '@mui/icons-material/Delete';

import EventCountdown from '../../elements/EventCountdown'
import TrackerTable from '../../elements/TrackerTable'

const pricingTimes = [
  {value:'current',label:'Current Time'},
  {value:'event_time',label:'Event Time'}
]

export default function IndexTrack(props) {
  const { createNotification } = useContext(NotificationContext)
  const store = useStore().getState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [loading,setLoading] = useState(false)

  //const [indexData,setIndexData] = useState({})
  const [indexEvents,setIndexEvents] = useState([])
  const [indexWeighting,setIndexWeighting] = useState({})
  const [activeEventIndex, setActiveEventIndex] = useState(null)

  //timers
  const [currentEventTime,setCurrentEventTime] = useState(null)
  const [nextRebalTime,setNextRebalTime] = useState(null)
  const [nextAdjustmentTime,setNextAdjustmentTime] = useState(null)

  //eventcompare
  const [fromTime,setFromTime] = useState(null)
  const [fromPriceTime,setFromPriceTime] = useState('current')
  const [toTime,setToTime] = useState(null)
  const [toPriceTime,setToPriceTime] = useState('current')
  const [trackerData,setTrackerData] = useState([])
  
  const teamData = useSelector(state => state.main.teamData)
  const isOwner = params.id.substr(0, params.id.lastIndexOf("-")) in teamData.indices ? true : false

  const {data:indexData={},isLoading:indexIsLoading} = useIndexQuery({id:params.id})
  const {data:indexEventsData=[],isLoading:eventsIsLoading} = useIndexEventsQuery({id:params.id,no_cache:true, full_data:true,map_identifiers:'symbol'})
  const {data:indexWeightingData={},isLoading:weightingIsLoading} = useIndexWeightingQuery({id:params.id,category:'symbol'})

  const [deleteTrigger] = useIndexEventDeleteMutation()
  const [runTrigger] = useIndexEventRunMutation()
  const [compareTrigger] = useIndexEventCompareMutation()

  useEffect(() => {(async()=>{
    /*
    var ie = await getIndexEvents(params.id)
    for (let i = 0; i < ie.length; i++) {
      ie[i].rowIndex = i
      ie[i].id = i
    }
    setIndexEvents(prevState => ie)
    console.log(ie)
    setLoading(false)
    */
    ///
    dispatch({type:'SET_PAGE_TITLE',payload:'Index Tracker'})
    document.title = `Index One | Index Tracker`
    if (indexIsLoading || eventsIsLoading){return}
    try{
      var currentDivisor = indexData.current_divisor
      setCurrentEventTime(currentDivisor)
      setToTime(currentDivisor)
      var ie = JSON.parse(JSON.stringify(indexEventsData))
      var nextRebalFound = false
      for (let i = 0; i < ie.length; i++) {
        ie[i].rowIndex = i
        if(ie[i].time == currentDivisor){
          ie[i].current_divisor = true
          ie[i].next_adj = false
          if(ie[i-1]){
            setFromTime(ie[i-1].time)
          }else{
            setFromTime(ie[i].time)
          }
        } else{
          ie[i].current_divisor = false
          if(i!==0  && ie[i-1].time === currentDivisor){
            ie[i].next_adj = true
            setNextAdjustmentTime(ie[i].time)
          } else {
            ie[i].next_adj = false
          }
        }
        if (nextRebalFound === false && ie[i].time > currentDivisor && ie[i].events.some(r=>["reconstitution","rebalancing"].includes(r))){
          nextRebalFound = true
          ie[i].next_rebal = true
          setNextRebalTime(ie[i].time)
        }
      }
      setIndexEvents(prevState => ie)
    } catch (e){
      console.log(e)
    }
    /*
    try {
      Promise.all([
        api.get(`index/${params.id}`),
        api.get(`index/${params.id}/events`,{'params':{no_cache:true, full_data:true,map_identifiers:'symbol'}}),
        api.get(`index/${params.id}/weighting`,{'params':{category:'symbol'}})
        //add request for current weightings...
      ]).then(([r1,r2,r3]) => {
        setIndexData(r1.data)
        var currentDivisor = r1.data.current_divisor
        setCurrentEventTime(currentDivisor)
        setToTime(currentDivisor)
        var ie = r2.data
        var nextRebalFound = false
        for (let i = 0; i < ie.length; i++) {
          ie[i].rowIndex = i
          if(ie[i].time == currentDivisor){
            ie[i].current_divisor = true
            ie[i].next_adj = false
            if(ie[i-1]){
              setFromTime(ie[i-1].time)
            }else{
              setFromTime(ie[i].time)
            }
          } else{
            ie[i].current_divisor = false
            if(i!==0  && ie[i-1].time === currentDivisor){
              ie[i].next_adj = true
              setNextAdjustmentTime(ie[i].time)
            } else {
              ie[i].next_adj = false
            }
          }
          if (nextRebalFound === false && ie[i].time > currentDivisor && ie[i].events.some(r=>["reconstitution","rebalancing"].includes(r))){
            nextRebalFound = true
            ie[i].next_rebal = true
            setNextRebalTime(ie[i].time)
          }
        }
        console.log(r3.data)
        setIndexWeighting(r3.data)
        setIndexEvents(prevState => ie)
        setLoading(false)
      })
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
    */

    ///

    
    ///
  })()},[indexEventsData,indexData])

  const columns = [
    { field: 'time', headerName: 'Time',width:120},
    { field: 'stage', headerName: 'Stage', flex:2, display: 'flex',
      renderCell: (params) => (
        <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
            {params.row.current_divisor && <Tooltip title='Current Event'><Skeleton sx={{ bgcolor: '#4ecdc4' }} variant="circular" width={15} height={15}/></Tooltip>}
            {params.row.next_adj && <Tooltip title='Next Adjustment'><KeyboardArrowRightIcon /></Tooltip>}
            {params.row.next_rebal && <Tooltip title='Next Rebalance'><KeyboardDoubleArrowRightIcon /></Tooltip>}
          {StageChip(params.row.stage)}
        </Stack>
      )
    },
    {
      field: 'events',
      headerName: 'Events',
      display: 'flex',
      flex:5,
      renderCell: (params) => (
          <>
                  {params.row.events.map((event) => (
                    <><Chip label={event} size="small"></Chip><br/></>
                  ))}
          </>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      width:200,
      renderCell: (params) => (
          <div>
            <Tooltip title="Delete"><IconButton onClick={e=>eventDelete(params.row.id,params.row.time)}><DeleteIcon fontSize='small'/></IconButton></Tooltip>
          </div>
      )
    }
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
        <div style={{flex:1}}/>
      </GridToolbarContainer>
    );
  }

  const testCaObject = {
    "dividend":[
      {
        "id":"abdsd",
        "security":"123ab",
        "amount":123
      }
    ]
  }


  const eventCompare = async () => {
    //setLoading(true)
    var payload = {
      "compare":[
        {"index_id":params.id,"divisor_time":fromTime,"pricing_time":fromPriceTime},
        {"index_id":params.id,"divisor_time":toTime,"pricing_time":toPriceTime}
      ]
    }
    const vals = await compareTrigger(payload).unwrap()
    console.log(vals)
    if(Object.keys(vals).length > 0){
      setTrackerData(vals.change_data)
    }
    setLoading(false)
  }

  const eventDelete = async (id,time) => {
    setActiveEventIndex(null)
    const resp = await deleteTrigger({id:id,time:time}).unwrap()
    console.log(resp)
    if (time === currentEventTime){
      createNotification({
        message: "Cannot delete current index event.",
        status: 'error',
      })
      setLoading(false)
      return
    }
    if (resp.id && resp.time){
      /*
      var prevEvents = JSON.parse(JSON.stringify(indexEvents))
      //delete from data
      for (let i = 0; i < prevEvents.length; i++) {
        var event = prevEvents[i]
        if (event.id === resp.id && event.time === resp.time){
          prevEvents.splice(i, 1)
          break
        }
      }
      //reindex rows
      for (let i = 0; i < prevEvents.length; i++) {
        prevEvents[i].rowIndex = i
      }
      setIndexEvents(prevEvents)
      */
      createNotification({
        message: "Index event deleted successfully.",
        status: 'success',
      })
    } else {
      createNotification({
        message: "Failed to delete index event, please contact support.",
        status: 'error',
      })
    }
    setLoading(false)
  }

  const eventRun = async () => {
    //setLoading(true)
    var payload = JSON.parse(JSON.stringify(indexEvents[activeEventIndex]))
    const originalObject = JSON.parse(JSON.stringify(payload))
    console.log(payload)
    const thisRowIdx = payload.rowIndex
    for (var key of ["rowIndex","next_adj","next_divisor","current_divisor"]) {
      delete payload[key]
    }
    console.log(payload)
    payload["persist"] = true
    const resp = await runTrigger(payload).unwrap()
    console.log(resp)
    /*
    if (Object.keys(resp).length > 0){
      let merged = {...originalObject,...resp}
      console.log(merged)
      var newIndexEvents = [...indexEvents]
      newIndexEvents[thisRowIdx] = merged
      setIndexEvents(newIndexEvents)
    }
    console.log(resp)
    */
    //setLoading(false)
  }

  function CorporateActionDetailTable(props){
    const caType = props.caType
    const caData = props.caData
    switch(caType) {
      case 'dividend':
        var keys = {
          "ex_date":"Ex Date",
          "amount":"Amount"
        }
        break;
      case 'split':
        var keys = {
          "ex_date":"Ex Date",
          "from_factor":"From Factor",
          "to_factor":"To Factor"
        }
        break;
      default:
        var keys = {}
        // code block
    }
    return(
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align='center'>{caType}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(keys).map(([key,value]) => (
              <TableRow key={key}>
                <TableCell sx={{fontWeight:400}}>{value}</TableCell>
                <TableCell>{caData[key]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )  
  }

  function CorporateActionTable({indexEvent}){

    const caTypes = ["dividend","split"]
    var caRows = {}
    for (let i = 0; i < caTypes.length; i++) {
      var thisType = caTypes[i]
      if(indexEvent.hasOwnProperty(thisType)){
        for (let ii = 0; ii < indexEvent[thisType].length; ii++) {
          var thisEvent = indexEvent[thisType][ii]
          if (caRows.hasOwnProperty(thisEvent.id)){
            caRows[thisEvent.id][thisType] = thisEvent
          } else {
            caRows[thisEvent.id] = {[thisType]:thisEvent,id:thisEvent.id}
          }
        }
      }
    }
    caRows = Object.values(caRows)

    function Row(rowData) {
      const row  = rowData.rowData
      const [open, setOpen] = useState(false);
      const thisEvents = Object.keys(row).filter(item => caTypes.includes(item))
      return (
        (<React.Fragment>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              {row.id}
            </TableCell>
            <TableCell align="right">{thisEvents.map((e)=>(<Chip label={e} size='small'/>))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Grid container spacing={2}>
                    {thisEvents.map(e=>(
                      <Grid size={6}>
                        <CorporateActionDetailTable caType={e} caData={row[e]}/>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>)
      );
    }
    return(
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>ID</TableCell>
              <TableCell align="right">Corporate Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {caRows.map((row) => (
              <Row rowData={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )}

  function WeightingTable({weighting}){
    const wCols = [
      { field: 'id', headerName: 'ID',flex:1},
      { field: 'weight', headerName: 'Shares', flex:1}
    ]
    if (weighting){
    return (
      <Paper sx={{height:'200px'}}>
        <DataGrid
          rowHeight={30}
          headerRowHeight={30}
          rows={Object.entries(weighting).map(e=> ({"id":e[0],"weight":e[1]}))}
          columns={wCols}
          autoPageSize
          disableMultipleSelection={true}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
    </Paper>
    )
    } else {
      return (
        <Paper sx={{height:'200px'}}>
          <Stack direction='row' alignItems='center' justifyContent='center' sx={{height:'100%'}}>
            <Stack direction='column' alignItems='center' justifyContent='center'>
              <Typography variant='h6'>No Weightings</Typography>
            </Stack>
          </Stack>
        </Paper>
      )
    }
  }

  function CurrentWeightingTable({weighting}){
    const wCols = [
      { field: 'id', headerName: 'ID',flex:1},
      { field: 'weight', headerName: 'Weight %', flex:1}
    ]
    if (weighting){
    return (
      <Paper sx={{height:'610px'}}>
        <DataGrid
          rowHeight={30}
          headerRowHeight={30}
          rows={Object.entries(weighting).map(e=> ({"id":e[0],"weight":e[1]*100}))}
          columns={wCols}
          autoPageSize
          disableMultipleSelection={true}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
    </Paper>
    )
    } else {
      return (
        <Paper sx={{height:'610px'}}>
          <Stack direction='row' alignItems='center' justifyContent='center' sx={{height:'100%'}}>
            <Stack direction='column' alignItems='center' justifyContent='center'>
              <Typography variant='h6'>No Weightings</Typography>
            </Stack>
          </Stack>
        </Paper>
      )
    }
  }

  return (
    (<Container maxWidth={false} disableGutters sx={{padding:'2em',backgroundColor:'#fafafa'}}>
      {loading && <LoadingScreen/>}
      {/* 
      {JSON.stringify(indexData)}
      {JSON.stringify(currentEventTime)}
      {JSON.stringify(nextRebalTime)}
      {JSON.stringify(nextAdjustmentTime)}
      {JSON.stringify(trackerData)}
      */}
      <Grid container spacing={2} sx={{marginBottom:'2em'}}>
        <Grid size={6}>
        <EventCountdown startTime={currentEventTime} rebalTime={nextRebalTime} adjTime={nextAdjustmentTime} />
        </Grid>
        <Grid size={6}>
          <Grid container alignItems='flex-start' justifyContent='flex-end'>
            {isOwner &&
              <>
              <Grid size="auto">
                <Tooltip title='New Event'>
                  <IconButton size='large' aria-label="newevent" onClick={e=> navigate("../newevent")}>
                    <ScheduleSendIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid size="auto">
                <Tooltip title='Rerun Event'>
                  <IconButton size='large' disabled={!activeEventIndex} aria-label="newevent" onClick={e=> eventRun()}>
                    <EventRepeatIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
              </>
            }
            <Grid size="auto">
              <Tooltip title='View Event'>
                <IconButton size='large' disabled={!activeEventIndex} aria-label="view" onClick={e=> navigate(`../events/${indexEvents[activeEventIndex].time}`)}>
                  <ManageSearchIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid size="auto">
              <Tooltip title='Simulate Event'>
                <IconButton size='large' disabled={!activeEventIndex} aria-label="view" onClick={e=> navigate(`../events/${indexEvents[activeEventIndex].time}`)}>
                  <ArchitectureIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{marginBottom:'2em'}}>
        <Grid size="grow">
        <Typography variant="h6" sx={{fontWeight:300}}>Index Events</Typography>
        <Paper sx={{ height: '50vh', width: '100%' }} elevation={5}>
          <DataGrid
            getRowId={(row) => row.rowIndex}
            rowHeight={40}
            rows={indexEvents}
            columns={columns}
            onRowClick={(rowData) => setActiveEventIndex(rowData.row.rowIndex)}
            autoPageSize
            disableMultipleSelection={true}
          />
        </Paper>
        </Grid>
        {activeEventIndex !== null &&
          <Grid size="grow">
            <Stack direction='column' spacing={2}>
              <Grid container>
                <Grid size="grow">
                  <Table size="small">
                    <TableRow>
                      <TableCell>Time</TableCell>
                      <TableCell>{indexEvents[activeEventIndex].time}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Completed At</TableCell>
                      <TableCell>{indexEvents[activeEventIndex].completed_at}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Stage</TableCell>
                      <TableCell>{StageChip(indexEvents[activeEventIndex].stage)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Divisor</TableCell>
                      <TableCell>{indexEvents[activeEventIndex].divisor}</TableCell>
                    </TableRow>
                  </Table>
                </Grid>
                <Grid>
                  <IconButton aria-label="close" onClick={e=>setActiveEventIndex(null)}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography>Index Weightings</Typography>
              <WeightingTable weighting={indexEvents[activeEventIndex].weighting} />
              <Typography>Corporate Actions</Typography>
              <Paper elevation={5}>
                <CorporateActionTable indexEvent={indexEvents[activeEventIndex]}/>
              </Paper>
              {/*<Button onClick={e=> rerunIndexEventId(params.id,indexEvents[activeEventIndex].time)}>Rerun</Button>*/}
              {isOwner &&
              <>
              <Button disabled onClick={e=> console.log("")}>Simulate</Button>
              </>
              }
          { <Button color='primary' size='small' startIcon={<AddIcon />} onClick={e=>navigate(`newevent`)}>New Event</Button> }
            </Stack>
          </Grid>
        }
      </Grid>
      <Grid container spacing={3}>
        <Grid size={6}>
          <Typography variant="h6" sx={{fontWeight:300}}>Current Index Weighting</Typography>
          <Paper elevation={5}>
            <CurrentWeightingTable weighting={indexWeightingData}/>
          </Paper>
        </Grid>
        <Grid size={6}>
          <Typography variant="h6" sx={{fontWeight:300}}>Generate Tracker Files</Typography>
          <Stack direction='row' spacing={1}>
              <Stack direction='column' spacing={1}>
                {['from','to'].map((i) => (
                  <Stack direction='row' spacing={1} alignItems='center' justifyContent='end'>
                    <Typography variant='body2'>{i == 'from' ? 'Compare From':'Compare To'}</Typography>
                    <TextField size='small' select value={i=='from'?fromTime:toTime} onChange={e=>i=='from'?setFromTime(e.target.value):setToTime(e.target.value)}>
                      {indexEvents.map((option) => (
                        <MenuItem key={option.time} value={option.time}>
                          {option.time}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField size='small' select value={i=='from'?fromPriceTime:toPriceTime} onChange={e=>i=='from'?setFromPriceTime(e.target.value):setToPriceTime(e.target.value)}>
                      {pricingTimes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                ))}
              </Stack>
              <LoadingButton variant='outlined' loading={loading} onClick={e=>eventCompare()}>Generate</LoadingButton>
            </Stack>
            <br/>
            <Paper elevation={5}>
              
              <TrackerTable trackerData={trackerData}/>
            </Paper>
        </Grid>
      </Grid>
    </Container>)
  );
}